import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { withRouter } from 'react-router-dom';
const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, history, currentUser } = props;
  const tawkToRef = useRef(null);
  const [showTawk, setShowTawk] = useState(false);
  const filteredSections = pageAssetsData?.[camelize(ASSET_NAME)]?.data?.sections?.filter(
    section =>
      section.sectionId !== 'golf-cart-communities' && section.sectionId !== 'featured_locations'
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTawk(true);
    }, 1000); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageBuilder
        pageAssetsData={{
          ...pageAssetsData?.[camelize(ASSET_NAME)]?.data,
          sections: filteredSections,
        }}
        inProgress={inProgress}
        error={error}
        history={history}
        currentUser={currentUser}
        fallbackPage={<FallbackPage error={error} />}
      />
      {showTawk ? (
        <TawkMessengerReact
          ref={tawkToRef}
          propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
          widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
        />
      ) : null}
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { currentUser } = state.user || {};
  return { pageAssetsData, inProgress, error, currentUser };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps), withRouter)(LandingPageComponent);

export default LandingPage;
